<template>
    <div>
        <v-container>
            <v-row justify="center">
                <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">	
                    <s-select-definition
                        label="Turno"
                        :def="1278"
                        v-model="TypeTurn"
                        clearable
                        @input="changeGroup()"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <s-select
                        :items="ProductionGroup"
                        item-value="PdgID"
                        item-text="PdgNameGroup"
                        label="Grupos de personas"
                        v-model="PdgID"
                        @input="change()"
                    >
                        <template v-slot:item="data">
                            {{ data.item.PdgNameGroup }}   
                        </template>
                    </s-select>						
                </v-col>

                <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <label  aria-hidden="true" class="v-label theme--light"><b>Hora Inicio</b></label>
                    <div class="relojito">
                      <datetime valueZone="America/Lima"  type="time" v-model="DlpHourInitialEstation" @input="enviarHora()">
                      </datetime>
					</div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>

import _sFrzCtnCmptGroupPerson from "@/services/FrozenProduction/FrzCtnLinesProcess.js"




  
  export default {

    props: {
        processInitial: {},
       
    },

    data: () => ({
        ProductionGroup:[],
        // modelDialog : false,
        PdgID: null,
        item:{},
        TypeTurn: "",
        DlpHourInitialEstation: '',
    }),
    
    methods: {
 
        lstGroupPerson(){
            console.log ("grupos [Componente]",this.processInitial)
            this.processInitial.DateProcess
            this.processInitial.TypeTurn = this.TypeTurn;
            this.processInitial.TypeCultive
            console.log("select group", this.processInitial);
            _sFrzCtnCmptGroupPerson.listGroupPerson(this.processInitial,this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.ProductionGroup = r.data     
                    console.log(this.ProductionGroup)        
                }
            })
        },
        change(){
            this.$emit("getPdgID", this.PdgID);
            
        },
        changeGroup()
        {
            this.lstGroupPerson();
        },
        enviarHora(){
            this.$emit("HourInitial",this.DlpHourInitialEstation)
        }
    },
    created () {
            //  this.lstGroupPerson();
            this.DlpHourInitialEstation = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
	}

  };
  </script>